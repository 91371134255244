

import React, {useEffect, useState} from 'react'
import { graphql } from 'gatsby'
import {Styling} from '../styles/pages/alumni.styled'
import {SplitStyling} from '../styles/pages/default-split.styled'
import Arrow from '../images/arrow_down_right.inline.svg'
import { handleLogin, isLoggedIn, isAlumni } from "../utils/auth"
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import gsap from 'gsap';
import axios from 'axios'
import Seo from '../components/global/seo'

export default function Alumni({data}) {
    const [instructionText, setInstructionText] = useState('Please enter your licence deposit code and click Continue to proceed.')
    const [depositCode, setDepositCode] = useState()

    useEffect(() => {
      if(typeof window !== 'undefined'){
        const params = new URLSearchParams(window.location.search)
        setDepositCode(params.get('c'))
      }
    })
    const handleDepositCode = (event) => {
        setDepositCode( event.target.value)
    } 
    const launchRedeemModal = () => {
      window.open(`https://admin.toolroomacademy.com/?c=${depositCode}`, '_blank', 'location=yes,height=570,width=520,directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no')
    } 


    return (
        <Styling>
            <Seo title={data.wpPage.seo.title} description={data.wpPage.seo.metaDesc} />
         
            <SplitStyling>
              
                <div className="left image-wrapper">
                    <GatsbyImage image={getImage(data?.wpPage?.acfRedeemImage?.redeemImage?.localFile)} className="image" alt={'test'} />
                </div>
                <div className="right set-password">
                    <div className="right-inner">
                        <h2 className="main_title"><Arrow /> Redeem your licence code</h2>
                        <div className="form-text">
                            <p>{instructionText}</p>
                        </div>
                         <div className="forms-wrapper">
                            
                            <div className="reset-form fakeForm-b">
                                <div className="form-styles">

                                    <div className="form-step-1-b form-step">
                                        <input type="text" placeholder="Deposit code" value={depositCode} onChange={(e) => handleDepositCode(e)} className="reset-email col-1"/>
                                        <button onClick={() => launchRedeemModal()} className="send-reset-form submit_button capsule_button black col-1">Next</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SplitStyling>
        </Styling>
    )
}

export const query = graphql`
  {
    wpPage(title: {eq: "Redeem"}) {
        id
        seo {
          metaDesc
          title
        }
        acfRedeemImage {
          redeemImage{
            localFile{
              childImageSharp {
                gatsbyImageData(
                  width: 2000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
    }
  }
`